import { Component, OnInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { WebsiteService } from 'src/app/_services/website.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
//import * as AOS from 'aos';
import { from } from 'rxjs';
import { LinkService } from '../../_services/langhfre.service';
import { Router } from '@angular/router';
import { LoadScriptService } from 'src/app/_services/load-script.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-technology-platforms',
  templateUrl: './technology-platforms.component.html',
  styleUrls: ['./technology-platforms.component.scss']
})
export class TechnologyPlatformsComponent implements OnInit {

  country: string;
  email: any;
  // emailEnterprise : FormGroup;
  contactForm: FormGroup;
  formSection: boolean = true;
  thankSection: boolean = false;
  isSubmitting: boolean = false;
  constructor(
    private toastr: ToastrService,
    private networkCall: WebsiteService,
    private fb: FormBuilder,
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    public loadScript: LoadScriptService
  ) {
    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      'contactFormSubjects': ['', Validators.required],
      'contactFormMessage': ['', Validators.required],
      'contactphoneNo': ['', Validators.required],
      'NatureBusinessType': ['', Validators.required],
      'DesignationTypes': ['', Validators.required],
    });
  }

  ngOnInit() {
    this.titleService.setTitle("Trinka Enterprise | Technology Platforms");
    this.metaTagService.updateTag({ name: 'description', content: "Trinka allows you to add real-time writing support within your application with ease. Build unique solutions by offering Trinka's writing suggestions." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Trinka Enterprise | Technology Platforms" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Trinka allows you to add real-time writing support within your application with ease. Build unique solutions by offering Trinka's writing suggestions." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/enterprise/technology-platforms' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/technologyPlatform.png' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Trinka Enterprise | Technology Platforms' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Trinka allows you to add real-time writing support within your application with ease. Build unique solutions by offering Trinka's writing suggestions." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/technologyPlatform.png' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/enterprise/technology-platforms' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/enterprise/technology-platforms' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/enterprise/technology-platforms' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/enterprise/technology-platforms' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/enterprise/technology-platforms' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/enterprise/technology-platforms' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/enterprise/technology-platforms' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/enterprise/technology-platforms' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/enterprise/technology-platforms' });

    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      // document.getElementById('main_header').classList.add('homepageHeader');
      $.getScript('../../../assets/js/hubslider.js', function () { });
      $.getScript('../../../assets/js/owl.carousel.min.js', function () { });
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      $.getScript('../../../assets/js/home_client_slider.js', function () { });
      //AOS.init();
      this.country = localStorage.getItem("country")? localStorage.getItem("country"):"en";
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai/"},{"@type":"ListItem","position":2,"name":"Enterprise","item":"https://www.trinka.ai/enterprise"},{"@type":"ListItem","position":3,"name":"Technology Platforms","item":"https://www.trinka.ai/enterprise/technology-platforms"}]}
    `;

    this._renderer2.appendChild(this._document.head, script);
    ire('identify', {'customerId':'', customerEmail: '', customProfileId: this.generateGuid()});
  }
  sectionValue(section: string) {
    this.formSection = true;
    this.thankSection = false;
  }

  sendContactUsForm() {
    if (this.contactForm.valid) {
      this.isSubmitting = true;
      if (localStorage.getItem("country-trinka") == null || localStorage.getItem("country-trinka") == "") {
        this.loadGeoAPIScript();
      }
      this.country = localStorage.getItem("country-trinka") ? localStorage.getItem("country-trinka") : "en";
      var query = this.contactForm.value.NatureBusinessType + "||" + this.contactForm.value.contactFormSubjects + "||" + this.contactForm.value.contactFormMessage + "||" + this.contactForm.value.contactFormName + "||" + this.contactForm.value.DesignationTypes + "||" + this.contactForm.value.contactphoneNo
      this.networkCall.postContactUs(this.contactForm.value.contactFormEmail, query, this.router.url).subscribe(result => {
        this.formSection = false;
        this.thankSection = true;
        this.country;
        this.toastr.success("Thanks! We will contact you shortly.")
        this.contactForm.reset();
      }, error => {
        this.toastr.warning("Something went wrong!")
      }).add(() => {
        this.isSubmitting = false;
      });
    } else {
      this.validateAllFormFields(this.contactForm)
    }
  }

  loadGeoAPIScript() {
    if (isPlatformBrowser(this.platformId)) {
      var scriptName = "geoIP"
      this.loadScript.loadScript(scriptName, '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js')
        .then(() => {
          console.log(`${scriptName} loaded successfully`);
          var onSuccess = function (location) {
            localStorage.setItem("country-trinka", location['country']['iso_code'])
          };

          var onError = function (error) {
            localStorage.setItem("country-trinka", "IN")
          };
          try {
            //@ts-ignore
            geoip2.city(onSuccess, onError);
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          console.error(`Failed to load ${scriptName}`);
        });
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  generateGuid() {
    var result, i, j;
    result = '';
    for(j=0; j<32; j++) {
      if( j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }


}
