import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { WebsiteService } from 'src/app/_services/website.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../../_services/langhfre.service';
import { Router } from '@angular/router';
import { LoadScriptService } from 'src/app/_services/load-script.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-legal-writing',
  templateUrl: './legal-writing.component.html',
  styleUrls: ['./legal-writing.component.scss']
})
export class LegalWritingComponent implements OnInit {

  contactForm: FormGroup;
  formSection: boolean = true;
  thankSection: boolean = false;
  country: string;
  isSubmitting: boolean = false;
  
  constructor(
    private toastr: ToastrService,
    private networkCall: WebsiteService,
    private fb: FormBuilder,
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    public loadScript: LoadScriptService
  ) {
    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      'contactFormSubjects': ['', Validators.required],
      'contactFormMessage': ['', Validators.required],
      'contactphoneNo': ['', Validators.required],
      'NatureBusinessType': ['', Validators.required],
      'DesignationTypes': ['', Validators.required],
    });
   }

  ngOnInit(): void {
    this.titleService.setTitle('Legal Proofreading and Editing Tool | AI Legal Checking Tools for Lawyers');
    this.metaTagService.updateTag({ name: 'description', content: "Improve your legal documents with our comprehensive legal proofreading and editing tool. Trinka's legal grammar checker ensures accuracy and compliance with specialized legal language. Perfect for law professionals, our tool meticulously checks for legal terminology, syntax, and clarity." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Legal Proofreading and Editing Tool | AI Legal Checking Tools for Lawyers' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Improve your legal documents with our comprehensive legal proofreading and editing tool. Trinka's legal grammar checker ensures accuracy and compliance with specialized legal language. Perfect for law professionals, our tool meticulously checks for legal terminology, syntax, and clarity." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/legal-writing' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/legal-writing/legal-writing-banner.png' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Legal Proofreading and Editing Tool | AI Legal Checking Tools for Lawyers' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Improve your legal documents with our comprehensive legal proofreading and editing tool. Trinka's legal grammar checker ensures accuracy and compliance with specialized legal language. Perfect for law professionals, our tool meticulously checks for legal terminology, syntax, and clarity." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/legal-writing/legal-writing-banner.png' });
    this.metaTagService.updateTag({ name: 'keywords', content: 'legal checking, legal proofreading, law proofreading, proofreading in law, ai tools for lawyers, legal transcript proofreader, proofread legal documents, legal grammar checker, legal proofreading software, legal editing and proofreading, legal proof reader, legal writing tool, legal writing grammar checker, tool for lawyers, legal proofreading services' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/legal-writing' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/legal-writing' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/legal-writing' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/legal-writing' });

    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org","@graph":[{"@type":"WebPage","@id":"https://www.trinka.ai/features/legal-writing#webpage","url":"https://www.trinka.ai/features/legal-writing","name":"Legal Proofreading and Editing Tool | AI Legal Checking Tools for Lawyers","isPartOf":{"@id":"https://www.trinka.ai#website"},"datePublished":"2022-07-09T05:22:52+00:00","dateModified":"2023-02-10T15:18:24+00:00","description":"Improve your legal documents with our comprehensive legal proofreading and editing tool. Trinka's legal grammar checker ensures accuracy and compliance with specialized legal language. Perfect for law professionals, our tool meticulously checks for legal terminology, syntax, and clarity.","inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://www.trinka.ai/features/legal-writing"]}]},{"@type":"WebSite","@id":"https://www.trinka.ai/#website","url":"https://www.trinka.ai","name":"Trinka AI","description":"Grammar Checker and AI proofreading and editing tool","publisher":{"@id":"https://www.trinka.ai#organization"},"alternateName":"The Complete AI Writing Assistant","inLanguage":"en-US"},{"@type":"Organization","@id":"https://www.trinka.ai/#organization","name":"Trinka AI","url":"https://www.trinka.ai","logo":{"@type":"ImageObject","inLanguage":"en-US","@id":"https://trinka.ai/assets/images/trinka-logo.png","url":"https://trinka.ai/assets/images/trinka-logo.png","contentUrl":"","caption":"Trinka AI"},"image":{"@type":"ImageObject","@id":"https://www.trinka.ai/assets/images/legal-writing/legal-writing-banner.png"},"sameAs":["https://www.facebook.com/thetrinkaai/","https://twitter.com/thetrinkaai","https://www.linkedin.com/company/thetrinkaai/","https://www.youtube.com/@Trinka-ai"]}]}
    `;
    this._renderer2.appendChild(this._document.head, script);

    let script1 = this._renderer2.createElement('script');
    script1.type = `application/ld+json`;
    script1.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai"},{"@type":"ListItem","position":2,"name":"Legal Writing","item":"https://www.trinka.ai/features/legal-writing"}]}
    `;
    this._renderer2.appendChild(this._document.head, script1);

    let script2 = this._renderer2.createElement('script');
    script2.type = `application/ld+json`;
    script2.text = `
    {"@context":"https://schema.org","@type":"Organization","name":"Trinka AI","url":"https://www.trinka.ai","logo":"https://www.trinka.ai/assets/images/trinka-logo.png","address":{"@type":"PostalAddress","streetAddress":"Office No 801, Techniplex – II Off SV Road","addressLocality":"Goregaon - West","addressRegion":"Mumbai","postalCode":"400062","addressCountry":"IN"},"sameAs":["https://www.facebook.com/thetrinkaai/","https://www.instagram.com/thetrinkaai/","https://twitter.com/thetrinkaai/","https://www.youtube.com/c/Trinka-ai","https://www.linkedin.com/company/thetrinkaai/",""]}
    `;
    this._renderer2.appendChild(this._document.head, script2);

    ire('identify', {'customerId':'', customerEmail: '', customProfileId: this.generateGuid()});
  }

  sectionValue(section: string) {
    this.formSection = true;
    this.thankSection = false;
  }
  

  sendContactUsForm() {
    if (this.contactForm.valid) {
      this.isSubmitting = true;
      if (localStorage.getItem("country-trinka") == null || localStorage.getItem("country-trinka") == "") {
        this.loadGeoAPIScript();
      }
      this.country = localStorage.getItem("country-trinka") ? localStorage.getItem("country-trinka") : "en";
      var query = this.contactForm.value.NatureBusinessType + "||" + this.contactForm.value.contactFormSubjects + "||" + this.contactForm.value.contactFormMessage + "||" + this.contactForm.value.contactFormName + "||" + this.contactForm.value.DesignationTypes + "||" + this.contactForm.value.contactphoneNo
      this.networkCall.postContactUs(this.contactForm.value.contactFormEmail, query, this.router.url).subscribe(result => {
        this.formSection = false;
        this.thankSection = true;
        this.country;
        this.toastr.success("Thanks! We will contact you shortly.")
        this.contactForm.reset();
      }, error => {
        this.toastr.warning("Something went wrong!")
      }).add(() => {
        this.isSubmitting = false;
      });
    } else {
      this.validateAllFormFields(this.contactForm)
    }
  }

  loadGeoAPIScript() {
    if (isPlatformBrowser(this.platformId)) {
      var scriptName = "geoIP"
      this.loadScript.loadScript(scriptName, '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js')
        .then(() => {
          console.log(`${scriptName} loaded successfully`);
          var onSuccess = function (location) {
            localStorage.setItem("country-trinka", location['country']['iso_code'])
          };

          var onError = function (error) {
            localStorage.setItem("country-trinka", "IN")
          };
          try {
            //@ts-ignore
            geoip2.city(onSuccess, onError);
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          console.error(`Failed to load ${scriptName}`);
        });
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  generateGuid() {
    var result, i, j;
    result = '';
    for(j=0; j<32; j++) {
      if( j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }

}
