import { Component, OnInit, ElementRef, NgZone, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
//import { NetworkServicesService } from 'src/app/networkCalls/network-services.service';
import { Title, Meta } from '@angular/platform-browser';
// import { katanaInput } from 'src/app/models/katana-input.service';
// import { katanaResult } from 'src/app/models/katana-result.service';
// import { CKEditorComponent } from 'ngx-ckeditor';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
// import { FeedbackModalComponent } from 'src/app/Components/feedback-modal/feedback-modal.component';
// import { Observable, from } from 'rxjs';
// import { MatDialog } from '@angular/material';
// import { ErrorModalComponent } from '../error-modal/error-modal.component';
// import { ProgressStatusService } from '../../services/progress-status-service'
//import { rejectError } from 'src/app/models/reject-suggetions.service';
// import { NGXLogger } from 'ngx-logger';
//import { acceptError } from 'src/app/models/accept-suggetions.service';
//import { trigger, transition, style } from '@angular/animations';
// import { $ } from 'protractor';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';
//import { MatRadioChange } from '@angular/material/radio';
//import { MatSnackBar } from '@angular/material/snack-bar';
//import { start } from 'repl';
// import { MatDialog } from '@angular/material/dialog';
// import { NoopScrollStrategy } from '@angular/cdk/overlay'
// import { DetectionLimitExhaustModalComponent } from '../detection-limit-exhaust-modal/detection-limit-exhaust-modal.component';
// import { EnglishGrammarCheckerComponent } from 'src/app/Components/english-grammar-checker/english-grammar-checker.component';
// import { environment } from 'src/environments/environment';

declare function ire(string,{}): any;
@Component({
  selector: 'app-spell-checker',
  templateUrl: './spell-checker.component.html',
  styleUrls: ['./spell-checker.component.scss']
})
export class SpellCheckerComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService,
    // public dialog: MatDialog,
    // public progressStatusService: ProgressStatusService,
    // private errorModalComponent: ErrorModalComponent,
    public lc: NgZone,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
    // private logger: NGXLogger,
  ) {
   // window.addEventListener("message", (d)=>{this.listenWindowMessage(d)});
    this.document.body.classList.add('nosnipptFooter');
  }

  ngOnInit() {
    // ele.classList.remove("loading")
    this.titleService.setTitle("Free Spell Checker Online – Trinka AI");
    this.metaTagService.updateTag({ name: 'description', content: "Free online Spell Checker by Trinka helps you instantly correct spelling errors and grammar." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Free Spell Checker Online – Trinka AI" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Free online Spell Checker by Trinka helps you instantly correct spelling errors and grammar." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/spell-checker' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/LearnGrammarwithTrinka.png' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Free Spell Checker Online – Trinka AI' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Free online Spell Checker by Trinka helps you instantly correct spelling errors and grammar." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://trinka.ai/assets/images/LearnGrammarwithTrinka.png' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/spell-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/spell-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/spell-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/spell-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/spell-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/spell-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/spell-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/corrector-ortografico' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/spell-checker' });
    if (isPlatformBrowser(this.platformId)) {

      if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
        $("#GCChromeButton").css("display", "none");
        $("#GCFirFoxButton").css("display", "none");
        $("#GCEdgeButton").css("display", "none");
        return 'Opera';

      } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        console.log("cgrome")
        $("#GCChromeButton").css("display", "block");
        $("#GCFirFoxButton").css("display", "none");
        $("#GCEdgeButton").css("display", "none");
        return 'Chrome';

      } else if (navigator.userAgent.indexOf("Safari") != -1) {
        $("#GCChromeButton").css("display", "none");
        $("#GCFirFoxButton").css("display", "none");
        $("#GCEdgeButton").css("display", "none");

        return 'Safari';

      } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        $("#GCChromeButton").css("display", "none");
        $("#GCFirFoxButton").css("display", "block");
        $("#GCEdgeButton").css("display", "none");
        return 'Firefox';

      }
      else if ((navigator.userAgent.indexOf("MSIE") != -1)) {
        $("#GCChromeButton").css("display", "none");
        $("#GCFirFoxButton").css("display", "none");
        $("#GCEdgeButton").css("display", "block");
        return 'IE';
      }
      else {
        return 'unknown';
      }
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org","@graph":[{"@type":"WebPage","@id":"https://trinka.ai/spell-checker/#webpage","url":"https://trinka.ai/spell-checker/","name":"Free Spell Checker Online – Trinka AI","isPartOf":{"@id":"https://trinka.ai/#website"},"datePublished":"2022-07-09T05:22:52+00:00","dateModified":"2023-02-10T15:18:24+00:00","description":"Free online Spell Checker by Trinka helps you instantly correct spelling errors and grammar.","inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://trinka.ai/spell-checker/"]}]},{"@type":"WebSite","@id":"https://trinka.ai/#website","url":"https://trinka.ai/","name":"Trinka AI","description":"Advanced Grammar Checker for Academic and Professional Writing - Trinka","publisher":{"@id":"https://trinka.ai/#organization"},"alternateName":"The Complete Writing Assistant","inLanguage":"en-US"},{"@type":"Organization","@id":"https://trinka.ai/#organization","name":"Trinka AI","url":"https://trinka.ai/","logo":{"@type":"ImageObject","inLanguage":"en-US","@id":"https://trinka.ai/assets/images/trinka-logo.png","url":"https://trinka.ai/assets/images/trinka-logo.png","contentUrl":"","caption":"Trinka AI"},"image":{"@type":"ImageObject","@id":"https://trinka.ai/assets/images/"},"sameAs":["https://www.facebook.com/thetrinkaai/","https://twitter.com/thetrinkaai","https://www.linkedin.com/company/thetrinkaai/","https://www.youtube.com/@Trinka-ai"]}]}
    `;

    this._renderer2.appendChild(this._document.head, script);

    let script1 = this._renderer2.createElement('script');
    script1.type = `application/ld+json`;
    script1.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai"},{"@type":"ListItem","position":2,"name":"Spell Checker","item":"https://www.trinka.ai/spell-checker"}]}
    `;

    this._renderer2.appendChild(this._document.head, script1);
    ire('identify', {'customerId':'', customerEmail: '', customProfileId: this.generateGuid()});
  }

  ngOnDestroy() {
    this.document.body.classList.remove('nosnipptFooter');
  }

  generateGuid() {
    var result, i, j;
    result = '';
    for(j=0; j<32; j++) {
      if( j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }
}
