import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';

declare function ire(string,{}): any;
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Privacy Policy - Trinka');
    this.metaTagService.updateTag({ name: 'description', content: 'AI-Powered Grammar and Writing Assistance that checks contextual grammatical errors and suggests advanced writing tips specific to your subject. Start Proofreading your writing now!' });
    this.metaTagService.updateTag({ name: 'keywords', content: 'spelling and grammar check, correct grammar sentence, grammar correction website, grammar punctuation check' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Privacy Policy - Trinka' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'AI-Powered Grammar and Writing Assistance that checks contextual grammatical errors and suggests advanced writing tips specific to your subject. Start Proofreading your writing now!' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/privacypolicy' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/privacypolicy' });
    if (isPlatformBrowser(this.platformId)) {
      //$.getScript('../../../assets/js/wisepop.js', function () { });
    }
    ire('identify', {'customerId':'', customerEmail: '', customProfileId: this.generateGuid()});
  }

  generateGuid() {
    var result, i, j;
    result = '';
    for(j=0; j<32; j++) {
      if( j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }

}
