import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-auto-edit',
  templateUrl: './auto-edit.component.html',
  styleUrls: ['./auto-edit.component.scss']
})
export class AutoEditComponent implements OnInit, AfterViewInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Automatic Grammar Correction for your MS Word file with Trinka');
    this.metaTagService.updateTag({ name: 'description', content: "Trinka automatically edits your MS Word document for grammar and spelling corrections. Download it with tracked changes and avoid the hassle of plug-in installations." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Automatic Grammar Correction for your MS Word file with Trinka' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Trinka automatically edits your MS Word document for grammar and spelling corrections. Download it with tracked changes and avoid the hassle of plug-in installations.' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/proofread-file' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/AutoEditBanner.jpg' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Automatic Grammar Correction for your MS Word file with Trinka' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Trinka automatically edits your MS Word document for grammar and spelling corrections. Download it with tracked changes and avoid the hassle of plug-in installations." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/AutoEditBanner.jpg' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/proofread-file' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/proofread-file' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/proofread-file' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/auto-file-edit' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/features/auto-file-edit' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/features/auto-file-edit' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/features/auto-file-edit' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features/auto-file-edit' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/features/auto-file-edit' });
    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      //$.getScript('../../../assets/js/wisepop.js', function () { });
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai/"},{"@type":"ListItem","position":2,"name":"Features","item":"https://www.trinka.ai/features"},{"@type":"ListItem","position":3,"name":"Grammar Checker - Proofread File","item":"https://www.trinka.ai/features/proofread-file"}]}
    `;
    this._renderer2.appendChild(this._document.head, script);

    let script1 = this._renderer2.createElement('script');
    script1.type = `application/ld+json`;
    script1.text = `
    {"@context":"http://schema.org","@type":"VideoObject","name":"Auto Edit MS Word File - Trinka Product Walkthrough","description":"Get your document auto-edited within minutes using the new Proofread File feature. Simply upload your file and take away a downloadable track changes file that contains automatically applied grammar and spelling revisions.  Save time with automatic edits on your MS Word documents. Download your edited file with track changes and finalize your document superfast!  Join us on our Social Channels ---------------------------------------------------  Twitter - https://twitter.com/thetrinkaai  Instagram - https://www.instagram.com/thetrinkaai/  Facebook - https://www.facebook.com/thetrinkaai  LinkedIn - https://www.linkedin.com/company/thetrinkaai/","thumbnailUrl":"https://i.ytimg.com/vi/6Z_PsveCtRQ/default.jpg","uploadDate":"2020-08-14T14:49:48Z","duration":"PT44S","embedUrl":"https://www.youtube.com/embed/6Z_PsveCtRQ","interactionCount":"6683"}
    `;
    this._renderer2.appendChild(this._document.head, script1);

    let script2 = this._renderer2.createElement('script');
    script2.type = `application/ld+json`;
    script2.text = `
    {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"What do I get when I upload a file for Proofread File?","acceptedAnswer":{"@type":"Answer","text":"You get the following once you upload your file for Auto Edit: The edited file with all changes in track changes. You can review these changes and accept or reject then using Microsoft Word or any other compatible Word processor. This file will also include comments explaining the corrections. You will also receive a detailed report which incorporates the number of revisions, type of grammatical errors made, and an overall writing quality score."}},{"@type":"Question","name":"Can I bulk upload multiple documents at the same time? Is there a limit?","acceptedAnswer":{"@type":"Answer","text":"You can upload as many documents as you wish to be automatically edited. There is no limitation on the number of documents that can be uploaded. Only 1 credit is used for every 5000 words in your file(s)."}},{"@type":"Question","name":"How do I try the Proofread File feature myself?","acceptedAnswer":{"@type":"Answer","text":"Just Sign up on Trinka. You will find the Proofread File option on the left navigation panel. Upload a document and click on start editing. Voila! Get your auto-edited document in minutes!"}},{"@type":"Question","name":"How many credits are consumed for using Proofread File?","acceptedAnswer":{"@type":"Answer","text":"Basic users get 2 free credits every month while Premium users get 5 free credits every month. Proofread File uses 1 credit for every 5000 words in your file."}}]}
    `;
    this._renderer2.appendChild(this._document.head, script2);
    ire('identify', {'customerId':'', customerEmail: '', customProfileId: this.generateGuid()});
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('#closedPopup, #wordPluginPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/6Z_PsveCtRQ" width="100%" height="450"></iframe>');
        }
      });

    }
  }

  generateGuid() {
    var result, i, j;
    result = '';
    for(j=0; j<32; j++) {
      if( j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }
}