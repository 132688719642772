import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../../_services/langhfre.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-online-paraphraser',
  templateUrl: './online-paraphraser.component.html',
  styleUrls: ['./online-paraphraser.component.scss']
})
export class OnlineParaphraserComponent implements OnInit, AfterViewInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Trinka Paraphraser | Rephrase Sentences with AI ');
    this.metaTagService.updateTag({ name: 'description', content: "Trinka Paraphraser helps rephrase sentences, paragraphs, essays, and articles to make them more clear without changing the meaning." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Trinka Paraphraser | Rephrase Sentences with AI ' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Trinka Paraphraser helps rephrase sentences, paragraphs, essays, and articles to make them more clear without changing the meaning.' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/paraphraser' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/paraphraser/online_paraphrase_banner.svg' });
    this.metaTagService.updateTag({ name: 'keywords', content: 'sentence rephraser, paraphraser, online paraphraser, rephrase online, rephrase sentence online,  rewrite the sentence, rewriter tool, rephrase tool, article rewriter tool, sentence changer, paragraph rewriter, rewrite content, sentence reworder, rephrase online free, rewrite sentences online, english rephrase, paragraph changer, sentence rephraser online free' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Trinka Paraphraser | Rephrase Sentences with AI ' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Trinka Paraphraser helps rephrase sentences, paragraphs, essays, and articles to make them more clear without changing the meaning." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/paraphraser/online_paraphrase_banner.svg' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/paraphraser' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/paraphraser' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/paraphraser' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/paraphraser' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/features/paraphraser' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/features/paraphraser' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/features/paraphraser' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features/paraphraser' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/features/paraphraser' });
    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      //$.getScript('../../../assets/js/wisepop.js', function () { });
    }
    ire('identify', {'customerId':'', customerEmail: '', customProfileId: this.generateGuid()});
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('#closedPopup, #wordPluginPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/qF_9Al4NHIY" width="100%" height="450"></iframe>');
        }
      });

    }
  }

  generateGuid() {
    var result, i, j;
    result = '';
    for(j=0; j<32; j++) {
      if( j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }

}
