import { Component, OnInit, OnDestroy, ElementRef, PLATFORM_ID, Inject, Renderer2, HostListener } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { WebsiteService } from 'src/app/_services/website.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
// import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';
import { LoadScriptService } from 'src/app/_services/load-script.service';
// import { analyzeAndValidateNgModules } from '@angular/compiler';

declare function ire(string,{}): any;

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit, OnDestroy {
  isYearlyChecked: boolean = true;
  formSection: boolean = true;
  thankSection: boolean = false;
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;
  contactUsSection: String;
  monthfinalSection: boolean = false;
  YearlyfinalSection: boolean = true;
  country: string;
  encodedData: any;
  decodedData: any;
  paymentModeOption: any;
  finalAmmount: any;
  isSticky = false;
  isSubmitting: boolean = false;

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private networkCall: WebsiteService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public paymentService: WebsiteService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService,
    private router: Router,
    private _renderer2: Renderer2,
    private el: ElementRef, private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(DOCUMENT) private document: Document,
    public loadScript: LoadScriptService
  ) {
    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      'contactFormSubjects': ['', Validators.required],
      'contactphoneNo': ['', Validators.required],
      'contactFormMessage': ['', Validators.required],
      'NatureBusinessType': ['', Validators.required],
      'DesignationTypes': ['', Validators.required],
    });
    this.document.body.classList.add('nosnipptFooter');
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem("country-trinka") == null || localStorage.getItem("country-trinka") == "") {
        this.loadGeoAPIScript();
      }
    }
    this.titleService.setTitle('Trinka Pricing Plans - Powerful Writing at Attractive Value');
    this.metaTagService.updateTag({ name: 'description', content: "Write better for free with Trinka's Basic Plan. Upgrade to Premium subscription to get powerful writing assistance at the best value." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Trinka Pricing Plans - Powerful Writing at Attractive Value' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Write better for free with Trinka's Basic Plan. Upgrade to Premium subscription to get powerful writing assistance at the best value." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/pricing' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Trinka Pricing Plans - Powerful Writing at Attractive Value' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Write better for free with Trinka's Basic Plan. Upgrade to Premium subscription to get powerful writing assistance at the best value." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/pricing' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/pricing' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/pricing' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/pricing' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/pricing' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/pricing' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/pricing' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/pricing' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/pricing' });
    if (isPlatformBrowser(this.platformId)) {
      //document.getElementById('main_header').classList.add('pricingPageHeader');
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      // // $.getScript('../../../assets/js/particles.min.js', function(){});

      // console.log(this.country);
        this.country = localStorage.getItem("country-trinka") ? localStorage.getItem("country-trinka") : "en";

        this.route.params.subscribe(params => {
          this.encodedData = params['id'];
          this.paymentService.getPlans(this.encodedData, 1).subscribe(resp => {
            if (resp['message'] == 'success') {
              this.decodedData = resp['data']
              this.paymentModeOption = this.decodedData;
              console.log("Country is: ", this.country);
              // console.log(this.decodedData);
              // console.log(this.decodedData[2].final_amount);
              // this.onSelectionPlan(this.paymentModeOption[2], 2)
              var annualAmount = this.decodedData[3].final_amount;
              this.finalAmmount = annualAmount / 12;
              // console.log(this.finalAmmount);
            }
          });
        });
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai/"},{"@type":"ListItem","position":2,"name":"Trinka AI Pricing","item":"https://www.trinka.ai/pricing"}]}
    `;

    this._renderer2.appendChild(this._document.head, script);
    ire('identify', {'customerId':'', customerEmail: '', customProfileId: this.generateGuid()});
  }


  loadGeoAPIScript() {
    if (isPlatformBrowser(this.platformId)) {
      var scriptName = "geoIP"
      this.loadScript.loadScript(scriptName, '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js')
        .then(() => {
          console.log(`${scriptName} loaded successfully`);
          var onSuccess = function (location) {
            localStorage.setItem("country-trinka", location['country']['iso_code'])
          };

          var onError = function (error) {
            localStorage.setItem("country-trinka", "IN")
          };
          try {
            //@ts-ignore
            geoip2.city(onSuccess, onError);
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          console.error(`Failed to load ${scriptName}`);
        });
    }
  }

  sectionValue(section: string) {
    this.contactUsSection = section
    this.formSection = true;
    this.thankSection = false;
  }

  sendContactUsForm() {
    if (this.contactForm.valid) {
      this.isSubmitting = true;
      var query = this.contactForm.value.NatureBusinessType + "||" + this.contactForm.value.contactFormSubjects + "||" + this.contactForm.value.contactFormMessage + "||" + this.contactForm.value.contactFormName + "||" + this.contactForm.value.DesignationTypes + "||" + this.contactForm.value.contactphoneNo
      this.networkCall.postContactUs(this.contactForm.value.contactFormEmail, query, this.router.url).subscribe(result => {
        this.formSection = false;
        this.thankSection = true;
        this.country;
        this.toastr.success("Thanks! We will contact you shortly.")
        this.contactForm.reset();
      }, error => {
        this.toastr.warning("Something went wrong!")
      }).add(() => {
        this.isSubmitting = false;
      });
    } else {
      this.validateAllFormFields(this.contactForm)
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
  monthyPricing() {
    this.monthfinalSection = true;
    this.YearlyfinalSection = false;
  }
  yearlyPricing() {
    this.monthfinalSection = false;
    this.YearlyfinalSection = true;
  }
  //   scroll(el: HTMLElement) {
  //     el.scrollIntoView();
  // }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      //document.getElementById('main_header').classList.remove('pricingPageHeader');
      this.document.body.classList.remove('nosnipptFooter');
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const element = this.el.nativeElement.querySelector('.table-container');
    const rect = element.getBoundingClientRect();

    if (rect.top <= 0) {
      // Add your CSS class when the element touches the top of the page
      this.renderer.addClass(element, 'sticky-class');
    } else {
      // Remove the CSS class when the element is not touching the top of the page
      this.renderer.removeClass(element, 'sticky-class');
    }
  }

  generateGuid() {
    var result, i, j;
    result = '';
    for(j=0; j<32; j++) {
      if( j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }
}
